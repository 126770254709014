@import "../../scss/base.scss";

.container {
    display: flex;
    justify-content: center;
    height: calc(100vh - 70px);
    @include mobile{
        margin: 0 15px;
    }
    &ImgWrapper {
        position: relative;
        width: 100%;

        @include mobile {
            height: 100%;
        }

        .errorImge {
            width: 100%;
            height: 647px;
            background-image: url(/404Desktop.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            @include mobile {
                background-image: url(/404Mobile.png);
                width: 100%;
                height: 323px;
                margin-top: 20px;
            }
        }
    }

    &Wrapper {
        width: 100%;
        max-width: 768px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
        .infoText {
            width: 100%;
            max-width: 500px;
            position: absolute;
            top: 360px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0px auto;
            @include mobile {
                top: 180px;
                display: flex;
                flex-direction: column;
            }
            h1 {
                width: 100%;
                max-width: 500px;
                margin: 0;
                margin-top: 30px;
                margin-bottom: 10px;
                font-family: Circular Std Book, Helvetica, Arial, sans-serif;
                font-weight: bold;
                font-size: 25px;
                line-height: 125%;
                text-align: center;
                color: #393939;

                @include mobile {
                  
                    font-size: 25px;
                }
            }

            p {
                margin: 0 50px;
                font: 16px Circular Std book, Helvetica, Arial, sans-serif;
                align-items: center;
                color: #000000;
                margin-bottom: 20px;
                @include mobile {
                    font-size: 16px;
                    margin: 20px 10px;
                }
            }
        }
    }
}
